.summary-counter {
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.summary-counter_icon {
  display: block;
  background: rgba(244, 235, 255, 0.1);
  padding: 9px;
  border-radius: 4px;
  line-height: 5px;
  height: auto;
  margin: auto 12px auto auto;
}

.summary-counter_data {
  margin-right: auto;
}

.summary-counter_title {
  margin: 0;
  line-height: 26px;
  font-size: 14px;
  color: #fff;
}

.summary-counter_value{
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
  color: #fff;
  margin: 0;
}

.summary .ant-card-body {
  padding: 27px 28px;
}

.summary-spacer {
  width: 1px;
  background: #fff;
  margin-right: 0;
  margin-left: 0;
}
