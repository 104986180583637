.bar-chart {
  position: relative;
}
.bar-cart_tick_text {
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
}
.bar-cart_tick_text-small {
  font-weight: 300;
}
.bar-chart_legend {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.bar-chart_legend_item {
  color:#fff;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  margin: 20px 0;
}
.bar-chart_legend_item_icon {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 7px;
}

/* Ensure all chart cards have the same height */
.chart-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.keyword-frequency-chart {
  height: 100%; /* This makes the height of the keyword frequency chart match its container */
}

.aspect-types-chart {
  height: 100%; /* This makes the height of the aspect types chart match its container */
}

.chart-wrapper {
  display: flex;
  align-items: start;
  justify-content: space-between;

  &:hover {
    .regions-downloads {
      display: flex;
    }
  }

  .regions-downloads {
    position: absolute;
    right: 0;
    top: -15px;
    display: none;
  }
}
