.chart-wrapper {
    display: flex;
    align-items: start;
    justify-content: space-between;
    overflow-x: scroll;

    svg {
        overflow-x: scroll;

        @media (max-width: 500px) {
            zoom: 0.5
        }
    }


    &:hover {
      .review-downloads {
        display: flex;
      }
    }

    .review-downloads {
        position: absolute;
        right: 0;
        top: -15px;
        display: none;
    }
}
