.yearSelect {
  position: absolute;
  left: 50%;

  span.yearLabel {
    color: white;
    font-size: 18px;
  }


  @media (max-width: 768px) {
    position: relative;
    left: 0;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .ant-dropdown-trigger.button-main {
      min-width: 50px;
      display: inline-block;
      text-align: center;
  }
}
