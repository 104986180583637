.aspect {
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, .1);
  border-radius: 14px;
  color: #fff;
  text-align: center;
  overflow: hidden;

  max-width: 90%;
  min-width: 150px;

  @media (max-width: 1300px) {
    zoom: 70%
  }

  .aspect-value {
    margin-top: 5px;
    font-size: 16px;
    font-weight: 300;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .aspect-value-counter {
    color: #93E396;
    font-weight: 600;
    font-size: 26px;
    line-height: 28px;
    margin-right: 10px;
    margin-bottom: 0;
  }
}

.aspect-top-container {
  display: flex;
  flex-direction: column;
  padding: 35px 13px;
  background: rgba(204, 204, 204, .1);
  zoom: 0.6;
}
.aspect-icon-container {
  height: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.aspect-icon {
  margin: auto;
}

.aspect-name {
  margin: 16px auto 0;
  font-size: 21px;
  line-height: 23px;
  font-weight: 300;
}

.aspect-radar-chart {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.overview-block.aspects {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  overflow-y: scroll;

  @media (max-width: 1300px) {
    grid-template-columns: repeat(3, 1fr);
    flex-basis: 25%;
  }

  @media (max-width: 1024px) {
    grid-template-columns: repeat(8, 1fr);
  }
}

.chart-wrapper {
  overflow-x: scroll;

  .radar-container {
    zoom: 75%;

    @media (max-width: 1300px) {
      zoom:60%
    }

    @media (max-width: 800px) {
      zoom:40%
    }
  }
}

.aspects-topbar {
  gap: 20px;
  .applied-filters {
    margin-bottom: 0;
  }
}

.aspect-inner-wrapper {
  display: flex;
  gap: 20px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
}
