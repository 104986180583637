.chart-tooltip {
  position: absolute;
  background: #37427A;
  border-radius: 6px;
  padding: 12px 15px;
  transform: translate(-50%, calc(-100% - 15px));
  visibility: hidden;
}
.chart-tooltip .chart-tooltip-title, .chart-tooltip .chart-tooltip-value{
  white-space: nowrap;
}
.chart-tooltip-title {
  color: #FFFFFF;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  font-weight: 500;
  text-transform: capitalize;
}
.chart-tooltip-value {
  color: #FDB022;
  font-size: 12px;
  margin: 0;
  line-height: 16px;
  font-weight: 700;
}
