.radar-container {
    width: 100%;
    max-width: 2000px;
    height: auto;
    max-height: 2000px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    position: relative;  /* Added to make tooltip positioning relative to container */

    @media (max-width: 1200px) {
      zoom: 0.6;
    }

    @media (max-width: 800px) {
       zoom: 0.4;
    }

    @media (max-width: 668px) {
       zoom: 0.2;
    }
}

.chart-card.radar-chart {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.radar-tooltip {
    position: absolute;
    background: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    z-index: 1000;  /* Added to ensure tooltip appears above other elements */
    max-width: 250px;  /* Added to control tooltip width */
    white-space: nowrap;  /* Added to prevent text wrapping */
}