.domain {
    stroke: #fff;
    display: none;
}

.tick text {
    fill: #fff;
}

.tick line {
    stroke: #fff;
    display: none;
}

rect {
    /* remove stroke */
    stroke: none;
}

#price-range-chart, #ranking-range-prices, .chart-wrapper {
  overflow-x: scroll;

  &:hover {
    .download-btns {
      display: flex;
    }

    .chart-tooltip {
        visibility: visible;
    }
  }
}

.download-btns {
  position: absolute;
  right: 0;
  top: -15px;
  display: none;
}

.top-section {
    @media (max-width: 768px) {
        flex-direction: column
    }
}
