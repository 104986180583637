.mlondola-about-page {
  min-height: 100vh;
  font-family: 'Arial', sans-serif;
  color: #fff;
}

.hero-container {
  position: relative;
  height: 50vh;
  background-image: url('../../assets/hero-image.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: -50px !important;
  margin-right: -50px !important;
  margin-top: -64px !important;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7));
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.hero-content {
  max-width: 800px;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero-content h1 {
  margin-top: 0;
  font-size: 4rem;
  margin-bottom: 1.5rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.hero-content p {
  font-size: 1.5rem;
  
  color: #fff;
  margin-bottom: 2rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.hero-buttons {
  display: flex;
  gap: 1.5rem;
  justify-content: center;
}

.hero-button {
  padding: 1rem 2rem;
  border-radius: 8px;
  font-weight: 600;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  border: none;
}

.about-btn {
  background-color: transparent;
  border: 2px solid #fff;
  color: #fff;
  width: fit-content;
}

.about-btn:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transform: translateY(-3px);
}

.explore-btn {
  background-color: #37427A;
  color: white;
  text-decoration: none;
  width: fit-content;
}

.explore-btn:hover {
  background-color: #303862;
  transform: translateY(-3px);
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

/* Technologies Section */
.technologies-section {
  /* border: 1px solid rgb(255, 255, 255); */
  margin: 0px auto 4rem;
  position: relative;
  z-index: 2;
  /* padding: 2rem; */
}

.technologies-section h2 {
  text-align: center;
  /* font-size: 2.5rem; */
  color: #fff;
  margin-bottom: 3rem;
}

.technologies-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
}

.technology-card {
  background-color: #10214F;
  opacity: 0.9;
  border-radius: 0.75rem;
  padding: 1.5rem;
  text-align: center;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.technology-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);
}

.technology-card p {
  color: #fff;
  font-size: 0.9rem;
  font-weight: 300;
}

.about-section {
  background-color: #10214F;
  opacity: 0.9;
  border-radius: 0.75rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
  padding: 3rem;
  margin-bottom: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-section h2 {
  text-align: center;
  color: #fff;
  margin-bottom: 2rem;
}

.about-content p {
  margin-bottom: 1.5rem;
  line-height: 1.6;
  color: #fff;
  font-weight: 300;
  font-size: 16px;
  column-count: 2;
  column-gap: 2rem;
  text-align: justify;

  @media (max-width: 768px) {
    column-count: 1;
    text-align: left;
  }
}

.partners-section {
  background-color: #10214F;
  opacity: 0.9;
  border-radius: 0.75rem;
  padding: 3rem;
  text-align: center;
}

.partners-section h2 {
  color: #fff;
  margin-bottom: 2rem;
}

.partners-logos {
  display: flex;
  justify-content: space-around;
  gap: 3rem;
  flex-wrap: wrap;
}

.partner-link {
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 0.7;
}

.partner-link:hover {
  opacity: 1;
  transform: translateY(-5px);
}

.home-sponsors-image {
  height: 80px;
  object-fit: contain;
}

@media (max-width: 1200px) {
  .technologies-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .hero-content h1 {
    font-size: 3rem;
  }
}

@media (max-width: 768px) {
  .hero-buttons {
    flex-direction: column;
    align-items: center;
  }

  .technologies-grid {
    grid-template-columns: 1fr;
  }

  .partners-logos {
    flex-direction: column;
    align-items: center;
  }

  .hero-content h1 {
    font-size: 2.5rem;
  }

  .hero-content p {
    font-size: 1.2rem;
  }
}

.hero-cta {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.explore-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #1e3a8a;
  color: white;
  padding: 12px 24px;
  border-radius: 8px;
  text-decoration: none;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.2s ease;
  gap: 10px;
}

.explore-button:hover {
  background-color: #2563eb;
  transform: translateY(-3px);
}

.button-icon {
  margin-left: 5px;
}
