.alert {
    position: relative;
    width: 100%;
    border-radius: 8px;
    padding: 16px;
    /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); */
    /* border: 1px solid #fff; */
  }
  
  .alert-content {
    display: flex;
    align-items: flex-start !important;
    gap: 12px;
  }
  
  .alert-icon {
    font-size: 16px;
  }
  
  .alert-body {
    width: 100%;
  }
  
.alert-title {
  color: #fff;
  margin-top: 0 !important;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 500;
}
  
  .alert-description {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.85);
  }
  
  .alert-default {
    background-color: #ffffff;
    border-color: #d9d9d9;
  }
  
  .alert-default .alert-icon-wrapper {
    color: #37427a;
  }
  
  .alert-info {
    background-color: #e6f7ff;
    border-color: #91d5ff;
  }
  
  .alert-info .alert-icon-wrapper {
    color: #37427a;
  }
  
  .alert-enter {
    opacity: 0;
    transform: scale(0.95);
  }
  
  .alert-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 200ms ease-out, transform 200ms ease-out;
  }
  
  .alert-exit {
    opacity: 1;
    transform: scale(1);
  }
  
  .alert-exit-active {
    opacity: 0;
    transform: scale(0.95);
    transition: opacity 200ms ease-out, transform 200ms ease-out;
  }

  .map-container {
    position: relative;
  }
  
  .map-overlay.dark {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: rgba(0, 0, 0, 0.7); */
    backdrop-filter: blur(2px);
    z-index: 1000;
  }
  
  .dark-theme-alert {
    background-color: rgba(0, 0, 0, 0.6);
    /* border: 1px solid rgba(255, 255, 255, 0.1) !important; */
    width: 80%;
    max-width: 400px;
  }
  
  .dark-theme-alert .ant-alert-message {
    color: #fff !important;
  }
  
  .dark-theme-alert .ant-alert-description {
    color: rgba(255, 255, 255, 0.85) !important;
  }
  
  .dark-theme-alert .ant-alert-icon {
    color: #37427a !important;
  }
  
  .dark-theme-alert .anticon {
    color: #37427a !important;
  }
  
  @media (max-width: 640px) {
    .alert {
      padding: 12px;
    }
  
    .alert-description {
      font-size: 13px;
    }
  }