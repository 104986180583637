.counter {

}
.counter-title {
  color: #98A2B3;
  font-size: 11.6px;
  font-weight: 500;
  margin: 0;
  line-height: 18.9px;
}
.counter-value {
  color: #FDB022;
  font-weight: 600;
  font-size: 24px;
  margin: 0;
  line-height: 29px;
}
.counter-dimension {
  margin: 0;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}
