/* .page-title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
} */

.page-title-header {
    /* display: flex; */
    /* justify-content: space-between; */
    /* align-items: center; */
    margin-bottom: 1rem;
    color: #fff;
    margin: 0;
    padding: 0;
}

.page-title-subtitle {
    /* font-size: 1.5rem; */
    font-weight: 300;
    /* margin: 0;
    padding: 0; */
    color: #fff;
}