.main-navigation {
  background: rgba(255,255,255,.1);
  border-radius: 0 12px 12px 0;
  width: 84px;
  height: 80%;
  margin-top: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  overflow: hidden;
  position: sticky;
  top: 10px;
}

.main-navigation-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #fff;
  background: rgba(255,255,255,0);
  font-size: 12px;
  line-height: 22px;
  transition: 200ms ease-in all;
}
.main-navigation-link:hover {
  color: #fff;
  background: rgba(255,255,255,0.1);
}
.main-navigation-link:active {
  background: rgba(255,255,255,0.2);
}
.main-navigation-link_icon {
  max-height: 16px;
  margin-bottom: 6px;
}
.main-navigation-link-active {
  background: rgba(255,255,255,0.065);
  background: rgba(255, 255, 255, 0.065);
  border: 2px solid white;
  border-radius: 15px;
}
