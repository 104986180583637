.distribution-item-container {
    max-width: 300px;
    margin: auto;
    margin-top: 0;
    margin-bottom:0;
    flex: 1 1 auto;
    align-items: stretch;
    display: flex;


}

div.ant-modal-content {
  background-color: black !important;

  .ant-modal-close{
    color: white;
    top: 0;
    inset-inline-end: 0
  }
}
.ant-modal-footer{
  display: none;
}
