.login-card {
    background-color: rgba(255, 255, 255, 0.1);
    padding-left: 67px;
    padding-right: 67px;
    padding-top: 37px;
    padding-bottom: 37px;
    border-radius: 12px;
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  @media (max-width: 1024px) {
    width: 50%;
  }

  @media (max-width: 768px) {
    width: 60%;
  }
}

.login-container {
    position: relative;
}

.login-logo-container {
display: flex;
justify-content: center;
width: 100%;
margin-bottom: 65px;

}

.login-logo {
    max-height: 120px;
}

.login-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 500;
    color: white;
    margin-bottom: 36px;

    @media (max-width: 768px) {
      font-size: 18px;
    }
}

.input-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 27px;
    width: 80%;
    
    @media (max-width: 768px) {
      width: 100%;
    }
}

.input-group label {
    color: white;
    margin-right: 14px;
    flex-shrink: 0;
    font-weight: 500;
    font-size: 20px;
}

.input-group input {
    height: 34px;
    width: 100%;
    padding: 10px;
    outline: none;

    &::placeholder {
      font-size: larger;
    }
}

.login-btn-container {
    display: flex;
    justify-content: flex-end;
}

.login-btn {
    /* padding: 10px 20px; */
    margin-top: 27px;
    width: 96px;
    height: 32px;
    border: none;
    border-radius: 4px;
    background-color: #374174;
    color: white;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
}

.login-btn:hover {
    background-color: #2e3a75;
}

.success-message {
    color: green;
    margin-top: 10px;
    text-align: center;
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 16px;
  }
  
  .error-message {
    color: rgb(169, 42, 42);
    margin-top: 10px;
    text-align: center;
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 16px;
  }