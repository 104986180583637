.StatisticalInfo {}
.overview {
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .overview {
    flex-wrap: wrap;
  }
}

.pie-chart-one-line {

  @media (max-width: 768px) {
    flex-direction: column;
  }

}
