.filters_button {
  display: flex;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
  /* margin-left: auto; */
}
.filters_button_icon {
  display: inline-block;
  margin-right: 5px;
  height: 18px;
  width: 18px;
}

/* Drawer */

.ant-drawer .ant-drawer-content {
  background: #081932;
  padding: 18px 36px 36px;
}

.ant-drawer .ant-drawer-header {
  padding: 0 0 9px;
}

.ant-drawer .ant-drawer-body {
  padding: 0 0 16px;
}

.filters-header {
  display: flex;
  justify-content: space-between;
}
.filters-title {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  margin: 0;
}
.filters-clear-button {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  margin: 0;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.filters-clear-button:hover {
  text-decoration: underline;
}

/* Inputs */

.filters-select-label {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  border-left: 4px solid #2E90FA;
  padding-left: 8px;
}