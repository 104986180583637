.App {
  .ant-spin-dot.ant-spin-dot-spin {
    font-size: 40px;
    margin-bottom: 30px;
  }

  .ant-spin-text {
    font-size: 30px;
  }

  .header-actions {
    display: flex;
    gap: 5px;
  }
}

*::-webkit-scrollbar {
    width: 2px;
    height: 2px; /* for horizontal scrollbar */
}
/* Scrollbar handle */
*::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
}

/* Scrollbar handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
aside {
    @media (max-width: 768px) {
        display: none;
    }
}

.root-layout {
    margin-left: 150px;
    margin-right: 50px;
    background: transparent;

    @media (max-width: 768px) {
        .ant-layout-header{
            padding: 0 20px !important;
        }
        margin-left: 20px;
        margin-right: 20px;
        padding: 0;
    }
}

.toggle-mobile-nav {
    display: none;
    z-index: 999;

     @media (max-width: 768px) {
         display: flex;
     }

    svg {
        zoom: 1.5
    }
}

.mobile-navigation {
    visibility: hidden;
    opacity: 0;
    transform: translateX(-100%);
    transition: all 0.5s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

     @media (max-width: 768px) {
         position: absolute;
         top: 0px;
         left: 0;

         .main-navigation {
             background-color: #10214F;
             height: 100vh;
             width: 25vw;
             height: 90vh;
             padding: 40px 0;
             margin-top: 10%;
             transform: translateX(-100%);
             transition: transform 0.5s ease-in-out;
         }

        .overlay {
            background-color: whitesmoke;
            opacity: 0;
            width: 100%;
            height: 100vh;
            position: absolute;
            top: 0;
            left: 0;
            transition: opacity 0.5s ease-in-out;
        }



         &.show {
             opacity: 1;
             width: 100%;
             visibility: visible;
             transform: translateX(0);
             overflow: hidden;
             z-index: 99;

             .overlay {
                background-color: whitesmoke;
                opacity: 0.6;
                width: 100%;
                transition: opacity 0.5s ease-in-out;
                left: 25vw;
             }

            .main-navigation {
                transform: translateX(0);

                .main-navigation-link_icon {
                    height: 30px;
                    max-height: unset;
                }
            }
         }
     }
}

@media (max-width: 400px) {
    .yearLabel, .filters_button, .header-actions button:nth-child(2) {
        display: none;
    }

    .top-establishments, .reviewers-countries{
        text-wrap: wrap;
    }

    .ant-btn {
        height: auto;
    }
}
