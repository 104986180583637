.country-list-item {
    margin-top: 16px;
    display: flex;
}
.country-list-item-index {
    margin-left: 0;
    margin-right: 16px;
    color: #A3B2FF;
    padding: 5px 0;
    font-size: 16px;
    font-weight: 600;
}
.country-list-item-values {
    display: flex;
    justify-content: space-between;
    padding: 5px 28px;
    border-radius: 5px;
    width: 100%;
}
.country-list-item-values:hover{
    background-color: rgba(194, 194, 194, 0.2);
}
.country-list-item-key, .country-list-item-value {
    color: #fff;
    font-size: 15px;
}
.search-input-region {
    background: transparent !important;
    color:#fff !important;
    border-color: #fff !important;
}
.season-counters-container {
    display: flex;
    justify-content: space-between;
    margin-top: -25px;
    margin-bottom: 49px;
}
.seasons-counter {
    background-color: #10214F;
    padding: 12px 20px;
    border-radius: 6px;
    width: 100%;
    margin-right: 16px;
    display: flex;
    flex-direction: column;
}
.seasons-counter:last-child {
    margin-right: 0;
}
.seasons-counter-value {
    color: #A3B2FF;
    font-size: 20px;
}
.seasons-counter-text {
    color:#fff;
    font-size: 14px;
    width: fit-content;
    margin: auto;
    padding-top: 5px;
}

#chart-with-legend-gender {
  flex-direction: column;

  .chart-container, .pie-chart-legend {
    align-items: center;
  }

  .legend-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.visitors-container {
  @media (max-width: 1070px) {
    flex-direction: column;
    > div {
      max-width: 100%;
    }
  }

  .season-counters-container {
    flex-wrap: wrap;
    gap: 10px;
  }

  .seasons-counter {
    min-width: 100px;
    width: 0;
    flex-grow: 1;
    margin-right: 0;
    text-align: center;
  }
  .chart-wrapper {
    justify-content: center;
    margin-right: 0
  }

  .visitors-by-country {
    @media (max-width: 768px) {
      flex-direction: column;
      gap: 20px;
    }
  }
}
.vistors-title-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
}
