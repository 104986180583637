.platform {
  display: flex;
  justify-content: center;
  align-items: center;
}
.platform_button {
  font-size: 14px;
  color: #fff;
  line-height: 25px;
  background: transparent;
  border: none;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 3px 15px 3px 13px;
  border-radius: 12px;
  cursor: pointer;
  transition: 200ms ease-in all;
}
.platform_button:hover {
  background-color: rgba(163, 178, 255, 0.1);
}

.platform_button:active {
  background-color: rgba(163, 178, 255, 0.2);
}
.platform_button_arrow {
  font-size: 10px;
  margin-left: auto;
}
.platform_button-active {
  background-color: rgba(163, 178, 255, 0.25);
}
.platform_button-active:hover {
  background-color: rgba(163, 178, 255, 0.3);
}
.platform_icon {
  height: 24px;
  width: 24px;
  min-width: 24px;
  margin-right: 14px;
  display: block;
  background-color: rgba(163, 178, 255, 0.1);
  border-radius: 4px;
}

.platform_icon_agoda, .platform_icon_airbnb {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 4px;
  padding: 2px;
}
.platform_icon-image {
  max-width: 100%;
}
