.section {
    margin-bottom: 49px;
    margin: auto;
    margin-bottom: 49px;
    margin-top: 0;
    margin-left: 0;
    display: flex;
    min-width: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: stretch;
}
.section .section {
    margin-bottom: 0;
}
.section .section + .section {
    margin-bottom: 49px;
}
.section-header {
    margin: 0 0 13px 0;
    color: #FFFFFF;
    font-weight: 300;
    font-size: 20px;
    line-height: 22px;
}