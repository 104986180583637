.chart-wrapper {
  position: relative;

  [id^="chart-with-legend-"] {
    display: flex;
    align-items: start;
    justify-content: space-between;
    overflow-x: scroll;
    gap: 10px;

    @media (max-width: 1070px) {
      flex-direction: column;
    }
  }
}

.chart-container {
  overflow-x: scroll;
  overflow-y: hidden;
}

.chart-container svg {
  display: block;
}

.treemap-legend {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  flex-basis: 40%;

  .legend-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    width: 100%;


    @media (max-width: 1070px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.legend-color {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.legend-text {
  font-size: 14px;
  color: white;
}

.tree-cell {
  transition: opacity 0.2s;
}

.download-btns {
  display: flex;
  gap: 8px;
  margin-top: 16px;
}

.download-btn {
  padding: 8px;
  background-color: #37427A;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.download-btn:hover {
  background-color: #44508e;
}

.read-more-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #37427A;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  color: white;
  font-weight: 500;
  transition: all 100ms linear;
  margin-left: auto;
  margin-right: auto; /* Center the button horizontally */
}

.read-more-btn:hover {
  background-color: #44508e;
}

.read-more-btn span {
  margin-right: 5px;
}