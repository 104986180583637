body {
  margin: 0;
  font-family: "Work Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
* {
  font-family: "Work Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
svg {
  overflow: hidden;
}

/* ant design ovverides */

.ant-layout {
  font-family: "Work Sans", sans-serif;
}

.ant-card {
  background: rgba(255,255,255,.03);
}

.ant-card .ant-card-body{
  padding: 23px 14px 18px 23px;
}

.ant-card-head {
  border: none;
}

.d-flex {
  display: flex;
}

.button-main {
  background: #37427A;
  border-radius: 6px;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  cursor: pointer;
  border: none;
  padding: 10px;
  transition: all 100ms linear;
  align-items: center;
  outline: none;
  box-shadow: 0px 3px 4px 0px rgba(12,20,67,0.3);
}
.button-main-inactive {
  background: rgba(55,66,122,0.5);
  text-decoration: solid;
  outline: white solid 2px;
}
.button-main:hover {
  background: #303862;
}

.button-main:active {
  background: #44508e;
}

.chart-container {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
}

.legend-item {
  display: flex;
  margin-bottom: 5px;
}
.legend-color {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  margin-right: 5px;
}
.legend-text {
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;
}
.chart-legend{
  margin-top: 25px;
}
.title-small {
  color: #FFFFFF;
  font-weight: 200;
  font-size: 24px;
}
