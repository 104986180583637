.applied-filters {
    color: #FFFFFF;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-wrap: wrap;
}

.filters-list {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
}

.filter-item {
    display: flex;
    gap: 0.2rem;
    align-items: flex-start;
}

.remove-filter {
    background: transparent;
    border: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    max-height: 15px;
}

/* on button hover change svg element color */
.remove-filter:hover svg {
    fill: #FF6347;
}
