.pie-chart-one-line {
    display: flex;
    gap: 20px;
    align-items: flex-start;
}
#chart-categories-pie {
  align-items: center;
}
.pie-chart-one-line .pie-chart-legend {
    /* margin-left: 46px; */
    /* margin-top: auto; */
    /* margin-bottom: auto; */
    /* align-self: center; */
}

.pie-chart-legend {
/* display in table-like layout of 3 columns */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    margin-top: 20px;
  }

  .legend-item {
    display: flex;
  }

  .legend-color {
    flex-shrink: 0;
  }

  .legend-text {
    flex-grow: 1;
  }

.download-btns {
    display: flex;
}

.pie-chart-legend {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.legend-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  width: 100%;
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.legend-color {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.legend-text {
  font-size: 14px;
  color: white;
}

.read-more-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  padding: 10px;
  background-color: #37427A;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  transition: all 100ms linear;
}

.read-more-btn:hover {
  background-color: #44508e;
}

.read-more-btn span {
  margin-right: 5px;
}
