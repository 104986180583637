.bar-chart {
  position: relative;
}
.bar-cart_tick_text {
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
}
.bar-cart_tick_text-small {
  font-weight: 300;
}
.bar-chart_legend {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.bar-chart_legend_item {
  color:#fff;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  margin: 20px 0;
}
.bar-chart_legend_item_icon {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 7px;
}

.legend-container {
  margin-left: 4rem;
}